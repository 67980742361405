<i18n>
{
  "de": {
    "newPortfolioTitle": "Portfolio hinzufügen",
    "portfolioOptionsLabel": "Portfolio",
    "loading": "Laden…",
    "submitButtonLabel": "Speichern",
    "roleLabel": "Berechtigung",
    "userNotFound": "Benutzer nicht gefunden",
    "roleLabels": {
      "USER": "USER",
      "EDITOR": "EDITOR",
      "MANAGER": "MANAGER"
    }
  }
}
</i18n>

<template>
  <main-layout :ready="allPortfoliosReady && allUsersReady" class="admin-add-portfolio-to-user-page">
    <template #default>
      <p v-if="error">{{ JSON.stringify(error) }}</p>
      <h1>{{ $t('newPortfolioTitle') }}</h1>
      <form>
        <label for="portfolio-options">{{ $t('portfolioOptionsLabel') }}</label>
        <v-select id="portfolio-options" v-model="portfolioId" :options="portfolioOptions" :reduce="portfolio => portfolio.id" />

        <label for="role-options">{{ $t('roleLabel') }}</label>
        <v-select id="role-options" v-model="role" :options="roleOptions" :reduce="role => role.key" />

        <button-wrapper>
          <button class="button" :disabled="!submitButtonEnabled" @click.prevent="onSubmit">{{ $t('submitButtonLabel') }}</button>
        </button-wrapper>
      </form>
    </template>
  </main-layout>
</template>

<script>
import MainLayout from '@/pages/layouts/MainLayout'
import AllPortfoliosMixin from '@/pages/vuex-mixins/AllPortfoliosMixin'
import AdminAllUsersMixin from '@/pages/vuex-mixins/AdminAllUsersMixin'
import AdminUserMixin from '@/pages/vuex-mixins/AdminUserMixin'

import ButtonWrapper from '@/components/shared/ButtonWrapper'
import { mapActions } from 'vuex'

export default {
  mixins: [
    AllPortfoliosMixin, // Provides: allPortfolios, allPortfoliosReady
    AdminAllUsersMixin, // Provides: allUsers, allUsersReady
    AdminUserMixin // Provides: user
  ],
  data () {
    return {
      role: null,
      portfolioId: null,
      error: null
    }
  },
  components: {
    ButtonWrapper,
    MainLayout
  },
  computed: {
    roleOptions () {
      return [
        {
          'key': 'USER',
          'label': this.$t('roleLabels.USER')
        },
        {
          'key': 'EDITOR',
          'label': this.$t('roleLabels.EDITOR')
        },
        {
          'key': 'MANAGER',
          'label': this.$t('roleLabels.MANAGER')
        }
      ]
    },
    userPortfolios () {
      return this.user && this.user.portfolios
    },
    portfolioOptions () {
      const portfolios = this.portfolios || []
      const existingPortfolios = this.userPortfolios || []
      return portfolios.filter((portfolio) => !existingPortfolios.find((ep) => ep.id === portfolio.id)).map((portfolio) => {
        return { label: portfolio.name, id: portfolio.id }
      })
    },
    submitButtonEnabled () {
      return Boolean(this.portfolioId) && this.role !== null
    }
  },
  methods: {
    ...mapActions({
      addPortfolioUser: 'portfolio/addPortfolioUser'
    }),
    async onSubmit () {
      try {
        await this.addPortfolioUser({ portfolioId: this.portfolioId, userId: this.user_id, role: this.role })
        this.$router.push({ name: 'adminUser', params: { user_id: this.user_id } })
      } catch (error) {
        this.error = error
      }
    }
  }
}
</script>

<style>
.admin-add-portfolio-to-user-page form {
  display: grid;
  grid-template-columns: repeat(2, auto);
  align-items: baseline;
  justify-content: start;
  gap: var(--spacing-m);
  column-gap: var(--spacing-l);
}

.admin-add-portfolio-to-user-page form button {
  grid-column-end: -1;
  justify-self: start;
}
</style>
